import React from "react";
import { Route, Routes } from "react-router-dom";
import LayoutWrapper from "../container/layout-wrapper";
import RwireHome from "../container/rwire-home";
import RWireExpert from "../pages/rwire-expert";
import RwirePatent from "../container/result-page";
import RWirePatentView from "../container/patent-view";
import RWireHistory from "../container/rwire-history";
import RWireCognizance from "../container/rwire-cognizance";
import Login from "../container/login";
import DownloadCenter from "../container/download-center";
import "rsuite/dist/rsuite.min.css";
import "react-datepicker/dist/react-datepicker.css";
import Forgot from "../container/forgot";
import RwireResetPassword from "../container/reset";
import RwireMaintainance from "../pages/rwire-maintainance/rwire-maintainance";
import PageNotFound from "../pages/rwire-page-not-found";
import "../assets/css/dark-mode/index.scss";
import RwireQuickView from "../container/rwire-quick-view";
import {
  RwireDashboardWrapper,
  RwireReportClasess,
  ProjectCreationWrapper,
  CreateProject,
  RwireReportNotes,
  ExistingProject,
  RwireReportAssignee,
  RwireReportInventor,
  RwireReportString,
  RwireReportDashboard,
} from "../pages/rwire-report";
import { RwireReportKeyword } from "../container/report";
import RwireReportRelevantResults from "../container/report/rwire-report-relevant-results";
import FinalReportView from "../container/report/final-report-view";
import RwireProjectUsers from "../container/report/rwire-project-users";
import RwireReportCitedReferences from "../container/report/rwire-report-cited-references";
import RwireService from "../pages/rwire-service";
import RwireAboutUs from "../pages/rwire-aboutus";
import RwireFaq from "../pages/rwire-faq";
import AcceptInvitation from "../components/report/accept-invitation";

const RoutesList = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<LayoutWrapper titleKey="default" />}
      ></Route>
      <Route
        path="/:locale/login"
        element={
          <LayoutWrapper titleKey="login-page">
            <Login />
          </LayoutWrapper>
        }
      />

      <Route
        path="/:locale/services"
        element={
          <LayoutWrapper titleKey="service-page">
            <RwireService />
          </LayoutWrapper>
        }
      />
      <Route
        exact
        path="/:locale/about-us"
        element={
          <LayoutWrapper titleKey="aboutus-page">
            <RwireAboutUs />
          </LayoutWrapper>
        }
      ></Route>
      <Route
        exact
        path="/:locale/faq"
        element={
          <LayoutWrapper titleKey="faq-page">
            <RwireFaq />
          </LayoutWrapper>
        }
      ></Route>

      <Route
        path="/:locale/download-center"
        element={
          <LayoutWrapper titleKey="download-center">
            <DownloadCenter />
          </LayoutWrapper>
        }
      />
      <Route
        path="/:locale/forgot-password"
        element={
          <LayoutWrapper titleKey="reset-password">
            <Forgot />{" "}
          </LayoutWrapper>
        }
      />
      <Route
        path="/:locale/reset/:token"
        element={
          <LayoutWrapper titleKey="forgot-password">
            <RwireResetPassword />{" "}
          </LayoutWrapper>
        }
      />
      <Route
        exact
        path="/:locale"
        element={
          <LayoutWrapper titleKey="home">
            <RwireHome />
          </LayoutWrapper>
        }
      ></Route>
      <Route
        exact
        path="/:locale/rwire-expert"
        element={
          <LayoutWrapper titleKey="home">
            <RWireExpert />
          </LayoutWrapper>
        }
      ></Route>
      <Route
        exact
        path="/:locale/rwire-patents"
        element={
          <LayoutWrapper titleKey="result-page">
            <RwirePatent />
          </LayoutWrapper>
        }
      ></Route>
      <Route
        path="/:locale/patent-view/:patentId"
        element={
          <LayoutWrapper titleKey="patent-view">
            <RWirePatentView />
          </LayoutWrapper>
        }
      />
      <Route
        exact
        path="/:locale/cognizance"
        element={
          <LayoutWrapper titleKey="cognizance">
            <RWireCognizance />
          </LayoutWrapper>
        }
      ></Route>
      <Route
        exact
        path="/:locale/history"
        element={
          <LayoutWrapper titleKey="history">
            <RWireHistory />
          </LayoutWrapper>
        }
      ></Route>
      <Route
        exact
        path="/:locale/maintainance"
        element={
          <LayoutWrapper titleKey="maintainance">
            <RwireMaintainance />
          </LayoutWrapper>
        }
      ></Route>
      <Route
        exact
        path="/:locale/quick-view"
        element={
          <LayoutWrapper titleKey="quick-view">
            <RwireQuickView />
          </LayoutWrapper>
        }
      ></Route>

      <Route
        exact
        path="/:locale/report"
        element={
          <LayoutWrapper titleKey="report">
            {/* <RwireDashboardWrapper> */}
            <RwireReportDashboard />
            {/* </RwireDashboardWrapper> */}
          </LayoutWrapper>
        }
      />

      <Route
        exact
        path="/:locale/create-project"
        element={
          <LayoutWrapper titleKey="createProject">
            <ProjectCreationWrapper>
              <CreateProject />
            </ProjectCreationWrapper>
          </LayoutWrapper>
        }
      />

      <Route
        exact
        path="/:locale/existing-project"
        element={
          <LayoutWrapper titleKey="existingProject">
            {/* <ProjectCreationWrapper> */}
            <ExistingProject />
            {/* </ProjectCreationWrapper> */}
          </LayoutWrapper>
        }
      />

      <Route
        exact
        path="/:locale/report/notes"
        element={
          <LayoutWrapper titleKey="reportClasess">
            <RwireDashboardWrapper>
              <RwireReportNotes />
            </RwireDashboardWrapper>
          </LayoutWrapper>
        }
      />

      <Route
        exact
        path="/:locale/report/keyword"
        element={
          <LayoutWrapper titleKey="reportKeyword">
            <RwireDashboardWrapper>
              <RwireReportKeyword />
            </RwireDashboardWrapper>
          </LayoutWrapper>
        }
      />

      <Route
        exact
        path="/:locale/report/classes"
        element={
          <LayoutWrapper titleKey="reportClasess">
            <RwireDashboardWrapper>
              <RwireReportClasess />
            </RwireDashboardWrapper>
          </LayoutWrapper>
        }
      />

      <Route
        exact
        path="/:locale/report/assignee"
        element={
          <LayoutWrapper titleKey="report-assignee">
            <RwireDashboardWrapper>
              <RwireReportAssignee />
            </RwireDashboardWrapper>
          </LayoutWrapper>
        }
      />

      <Route
        exact
        path="/:locale/report/inventor"
        element={
          <LayoutWrapper titleKey="report-inventor">
            <RwireDashboardWrapper>
              <RwireReportInventor />
            </RwireDashboardWrapper>
          </LayoutWrapper>
        }
      />
      <Route
        exact
        path="/:locale/report/string"
        element={
          <LayoutWrapper titleKey="report-inventor">
            <RwireDashboardWrapper>
              <RwireReportString />
            </RwireDashboardWrapper>
          </LayoutWrapper>
        }
      />
      <Route
        exact
        path="/:locale/report/relevant-results"
        element={
          <LayoutWrapper titleKey="report-relevant-results">
            <RwireDashboardWrapper>
              <RwireReportRelevantResults />
            </RwireDashboardWrapper>
          </LayoutWrapper>
        }
      />
      <Route
        exact
        path="/:locale/report/final-view"
        element={
          <LayoutWrapper titleKey="report-final-view">
            <FinalReportView />
          </LayoutWrapper>
        }
      />
      <Route
        exact
        path="/:locale/report/users"
        element={
          <LayoutWrapper titleKey="project-users">
            <RwireProjectUsers />
          </LayoutWrapper>
        }
      />
      <Route
        exact
        path="/:locale/report/cited-references"
        element={
          <LayoutWrapper titleKey="report-cited-references">
            <RwireDashboardWrapper>
              <RwireReportCitedReferences />
            </RwireDashboardWrapper>
          </LayoutWrapper>
        }
      />

      <Route
        exact
        path="/:locale/report/invitation-accepted"
        element={<AcceptInvitation />}
      />

      <Route exact path="*" element={<PageNotFound />}></Route>
    </Routes>
  );
};

export default RoutesList;
