import React from "react";
import { getMostRelevantHighlights } from "../../common/get-most-relevant-highlights";
import replaceSpecialChars from "../../common/replace-special-chars";
import { HighlightFromQueryOrManually } from "../../container/highlight";

const SingleResultPatent = (props) => {
  const {
    result = {},
    onSetPatentLink,
    setSelectedIndex,
    index,
    selectedIndex,
    OnQuickViewOnPN,
    quickViewHighlights,
  } = props;

  const replaceAndTrimSpecialChars = (text) => {
    return replaceSpecialChars(text).replace(/^\w+-\w+"\s*/, "");
  };

  const handleSelectQuickView = () => {
    OnQuickViewOnPN(result?._source?.PN_B);
    setSelectedIndex(index);
  };

  return (
    <div
      className={`px-2 py-1 mb-2 single-result-patent cursor-pointer ${
        selectedIndex === index ? "selected" : ""
      }`}
      onClick={handleSelectQuickView}>
      <div
        onClick={() => onSetPatentLink(result?._source?.PN_B)}
        className="fw-bold pub-no">
        <HighlightFromQueryOrManually>
          {replaceSpecialChars(result?._source?.PN_B)}
        </HighlightFromQueryOrManually>
      </div>
      <div className="twoLinesEllipse details-text">
        <HighlightFromQueryOrManually>
          {replaceAndTrimSpecialChars(
            getMostRelevantHighlights(
              quickViewHighlights[index]?.highlight
                ? quickViewHighlights[index].highlight
                : []
            )
          )}
        </HighlightFromQueryOrManually>
      </div>
    </div>
  );
};

export default SingleResultPatent;
