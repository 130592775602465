import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import RWireButton from "../../components/rwire-ui/rwire-button/rwire-button";
import FilterIcon from "../../assets/images/FilterIcon.png";
import { FiChevronDown } from "react-icons/fi";
import { Checkbox, CheckboxGroup } from "rsuite";
import { HighlightFromQueryOrManually } from "../../container/highlight";
import CitedByFiter from "./rwire-view-modal-subsections/citedby-filter";
export const citedByMapping = {
  0: "SEA - Search Report",
  1: "APP - Applicant cited",
  2: "EXA - Examination citation",
  3: "OPP - Opposition citation",
  4: "TPO - Third-party citation",
  5: "OTH - Other",
  6: "CH2 - PCT cited",
  E: "CEX - Examiner cited",
  O: "OEX - Citations(Examiner not included)",
  7: "ISR - Int. Search Report",
  8: "SUP - Supplementary Search Report",
  9: "PRS - Pre Search",
  10: "APL - Appealed",
  11: "FOP - Filed Opposition",
};

function Citations(props) {
  const {
    citations = [],
    forward_citations = [],
    extraClass,
    citationsFilterValue,
    setCitationsFilterValue,
    backward_citations_count = 0,
    forward_citations_count = 0,
    detailsData,
    smartReaderModal = "false",
  } = props;

  const [filterToggle, setFilterToggle] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [citedByFilterToggle, setCitedByFilterToggle] = useState(false);
  const [citedByFilterValue, setCitedByFilterValue] = useState([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "E",
    "O",
    "7",
    "8",
    "9",
    "10",
    "11",
  ]);

  useEffect(() => {
    const handleClick = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setFilterToggle(false);
      }
    };
    const handleScroll = () => {
      const dropdownMenu = dropdownRef.current;
      if (
        dropdownMenu &&
        window.scrollY > buttonRef.current.getBoundingClientRect().bottom &&
        window.scrollY > dropdownMenu.getBoundingClientRect().top
      ) {
        setFilterToggle(false);
      }
    };
    document.addEventListener("click", handleClick);
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [buttonRef, dropdownRef]);
  function findObjectByPublicationNo(obj1, obj2, pubNo) {
    let found = "Backward Citation";
    obj1.forEach((item) => {
      if (item.PN === pubNo) {
        found = "Backward Citation";
      }
    });
    obj2.forEach((item) => {
      if (item.PN === pubNo) {
        found = "Forward Citation";
      }
    });
    return found;
  }

  function createUniqueCitations(obj1 = [], obj2 = []) {
    let merged = [];
    obj1.forEach((item) => {
      if (!merged.find((elem) => elem.PN === item.PN)) {
        merged.push(item);
      }
    });
    obj2.forEach((item) => {
      if (!merged.find((elem) => elem.PN === item.PN)) {
        merged.push(item);
      }
    });
    return merged;
  }

  // const handleClick = (PN) => {
  //   sessionStorage.setItem("patentId", PN);
  //   onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: false });
  //   navigate(`/en/patent-view/${PN}`);
  // };

  const handleChange = (e) => {
    setCitationsFilterValue(e);
  };
  const shouldDisplayDropdown =
    forward_citations_count > 0 && backward_citations_count > 0;

  const handleChangeCitedBy = (e) => {
    setCitedByFilterValue(e);
  };

  const filterCitationsByCitedBy = (selectedCitedByValues) => {
    const allCitations = createUniqueCitations(citations, forward_citations);
    const filteredCitations = allCitations.filter((bcp) => {
      return bcp.CTBY === "" || selectedCitedByValues.includes(bcp.CTBY);
    });
    return filteredCitations;
  };

  useEffect(() => {
    setCitedByFilterValue([
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "E",
      "O",
      "7",
      "8",
      "9",
      "10",
      "11",
    ]);
  }, [detailsData?.PN_B]);

  const filteredCitations = filterCitationsByCitedBy(citedByFilterValue);
  return (
    <>
      <h5 className="legal-events-heading citations-table mt-5 mb-2">
        Citations:
      </h5>

      {forward_citations_count > 0 || backward_citations_count > 0 ? (
        <div
          className={`view-tables ${
            extraClass ? extraClass : ""
          } citations_table`}
        >
          <table className="css-serial">
            <thead className="table border table-borderless">
              <tr>
                <th
                  className={`${
                    extraClass
                      ? "citations__first__col_modal"
                      : "citations__first__col"
                  }`}
                >
                  Sr. No
                </th>
                <th>Publication Number</th>
                <th className="pub_date">Publication Date</th>
                <th className="app_date">Application Date</th>

                <CitedByFiter
                  citedByFilterValue={citedByFilterValue}
                  handleChangeCitedBy={handleChangeCitedBy}
                  citedByMapping={citedByMapping}
                  citedByFilterToggle={citedByFilterToggle}
                  setCitedByFilterToggle={setCitedByFilterToggle}
                  citations={citations}
                  forward_citations={forward_citations}
                  dropdownRef={dropdownRef}
                  extraClass={extraClass}
                />
                <th
                  className="citation__filter__btn 
                    citations__filter_column"
                >
                  <div
                    className="d-flex p-0 justify-content-between cursor-pointer"
                    onClick={() => setFilterToggle(!filterToggle)}
                    ref={buttonRef}
                  >
                    <RWireButton
                      cNameDiv="p-0 citations_filter"
                      buttonCName="text-left"
                    >
                      {shouldDisplayDropdown && (
                        <img
                          src={FilterIcon}
                          alt="Filter Icon"
                          className="mr-1"
                        />
                      )}
                      Filter Citations
                    </RWireButton>
                    {shouldDisplayDropdown && (
                      <FiChevronDown
                        style={{ fontSize: "20px", color: "#000" }}
                      />
                    )}
                  </div>

                  {filterToggle && shouldDisplayDropdown && (
                    <CheckboxGroup
                      className="p-0"
                      value={citationsFilterValue}
                      onChange={handleChange}
                    >
                      <div
                        className={`citation__filter__modal ${
                          smartReaderModal === "true"
                            ? "smart-reader-margin"
                            : ""
                        }`}
                        ref={dropdownRef}
                      >
                        <div className="citation__filter__checkbox">
                          {backward_citations_count > 0 && (
                            <Checkbox value="bcp">Backward Citation</Checkbox>
                          )}
                        </div>
                        <div className="citation__filter__checkbox">
                          {forward_citations_count > 0 && (
                            <Checkbox value="fcp">Forward Citation</Checkbox>
                          )}
                        </div>
                      </div>
                    </CheckboxGroup>
                  )}
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredCitations.map((bcp) => {
                return (
                  <tr>
                    <td
                      className={`${
                        extraClass
                          ? "citations__first__col_modal"
                          : "citations__first__col"
                      }`}
                    ></td>
                    <td
                      className={`${
                        extraClass
                          ? "modal_citations_pub_no"
                          : "citations_pub_no"
                      }`}
                      onClick={() => {
                        // handleClick(bcp.PN)
                      }}
                    >
                      <HighlightFromQueryOrManually>
                        {bcp.PN}
                      </HighlightFromQueryOrManually>
                    </td>
                    <td>
                      {bcp.PDT && moment(bcp.PDT).isValid() && (
                        <HighlightFromQueryOrManually>
                          {moment(bcp.PDT).format("LL")}
                        </HighlightFromQueryOrManually>
                      )}
                    </td>
                    <td>
                      {bcp.ADT && moment(bcp.ADT).isValid() && (
                        <HighlightFromQueryOrManually>
                          {bcp.ADT !== "" && moment(bcp.ADT).format("LL")}
                        </HighlightFromQueryOrManually>
                      )}
                    </td>
                    <td
                      className={`${
                        extraClass ? "modal_cited__by__col" : "cited__by__col"
                      }`}
                    >
                      {bcp.CTBY && (
                        <HighlightFromQueryOrManually>
                          {bcp.CTBY !== "" && citedByMapping[bcp.CTBY]}
                        </HighlightFromQueryOrManually>
                      )}
                    </td>
                    <td
                      className={`${extraClass ? "" : "citation__filter__col"}`}
                    >
                      <HighlightFromQueryOrManually>
                        {findObjectByPublicationNo(
                          citations,
                          forward_citations,
                          bcp.PN
                        )}
                      </HighlightFromQueryOrManually>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="pt-2 patnet_view_dna">Data not available</p>
      )}
    </>
  );
}

export default Citations;
