const getRegEx = (prePostRegx, newHighlightRegex) =>
  new RegExp(
    `${prePostRegx}((?:${newHighlightRegex
      .join("|")
      .replaceAll("?", "[a-zA-Z0-9]")
      .replaceAll("*", "\\w*")
      .replaceAll("+", "")}))${prePostRegx}`,
    "gi"
  );

const HighLighter = (text, highlight = [], isExact = true, queryRegex) => {
  let finalQueryRegex = !queryRegex
    ? localStorage.getItem("queryRegex")
    : queryRegex;
  const highlightedWords =
    JSON.parse(localStorage.getItem("highlightedWords")) || [];
  const storedQueryWords = JSON.parse(localStorage.getItem("queryWords")) || [];

  if (!highlightedWords.length && storedQueryWords.length === 0) return text;

  const wordsToHighlight =
    highlightedWords.length > 0
      ? highlightedWords
      : storedQueryWords.map((word) => ({
          word,
          color: "#fffb00",
          isQueryWord: true,
        }));

  const newHighlight = wordsToHighlight
    .filter((v) => !v.isQueryWord)
    .map((v) => `${v.word.toLowerCase()}`);
  const prePostRegx = isExact ? "\\b" : "";
  const highlightreg =
    newHighlight.length > 0
      ? `|${prePostRegx}((?:${newHighlight
          .join("|")
          .replaceAll("?", "[a-zA-Z0-9]")
          .replaceAll("*", "\\w*")
          .replaceAll("+", "")}))${prePostRegx}`
      : "";
  const highlightRegex = `(?:${finalQueryRegex})${highlightreg}`;
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, "text/html");
  const traverse = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.textContent;
      const matchData = [
        ...textContent.matchAll(new RegExp(highlightRegex, "gmid")),
      ];
      let outputString = textContent;
      if (matchData.length > 0) {
        const res = [];
        matchData
          .reverse()
          .filter((item) => item)
          .map((i) => {
            i.indices
              .slice(1)
              .reverse()
              .filter((item) => item)

              .map((it) => res.push(it));
          });
        // Flatten the array using reduce and concat
        const flattenedArray = res.reduce((acc, val) => acc.concat(val), []);

        // Create a Set to remove duplicates
        const uniqueArray = Array.from(new Set(flattenedArray));

        // Optionally, if you want to keep the result in nested pairs like the original array
        const resultArray = [];
        for (let i = 0; i < uniqueArray.length; i += 2) {
          resultArray.push(uniqueArray.slice(i, i + 2));
        }

        resultArray
          .sort((a, b) => b[0] - a[0])
          .forEach(([start, end]) => {
            let matchedWord = outputString.slice(start, end);

            outputString =
              outputString.slice(0, start) +
              `<span class="highlight-word" style="background-color: ${
                wordsToHighlight.filter(
                  (i) =>
                    i.word.toLowerCase().trim() ===
                    matchedWord.toLowerCase().trim()
                )[0]?.color || ""
              }">` +
              outputString.slice(start, end) +
              "</span>" +
              outputString.slice(end);
          });
        const highlightedText = outputString;
        const wrapper = document.createElement("div");
        wrapper.innerHTML = highlightedText;
        const highlightedNodes = Array.from(wrapper.childNodes);
        // debugger;
        highlightedNodes.forEach((n) => node.parentNode.insertBefore(n, node));
        node.remove();
      }
    } else if (
      node.nodeType === Node.ELEMENT_NODE &&
      node.nodeName.toLowerCase() === "span" &&
      node.classList.contains("font-weight-bold") &&
      node.classList.contains("cursor-pointer")
    ) {
      return;
    } else {
      for (const childNode of node.childNodes) {
        traverse(childNode);
      }
    }
  };
  traverse(doc.body);
  const serializedHighlighted = new XMLSerializer().serializeToString(doc.body);
  return serializedHighlighted;
};

export default HighLighter;
