import React from "react";
import { headingMapping } from "../../common/quick-view-heading-map";
import replaceSpecialChars from "../../common/replace-special-chars";
import { HighlightFromQueryOrManually } from "../../container/highlight";

export const processString = (str) => {
  return str
    .replace(/^[\s,/]+/, "")
    .replace(/^\w+-\w+"\s*/, "")
    .replace(/num="[^"]*">/g, "")
    .replace(/\d{4}">/g, "");
};

const SingleHighlightedFieldData = (props) => {
  const { displayData = [], field = "", key } = props;
  return (
    <div>
      {field && headingMapping[field] && displayData[field] && (
        <>
          <p className="px-3 fw-bold text-black py-1">
            {headingMapping[field]}
          </p>
          <ul>
            {displayData?.[field].map((str) => (
              <li class="text-black">
                <div className="details-text details-desc" key={key}>
                  <HighlightFromQueryOrManually>
                    {replaceSpecialChars(processString(str))}
                  </HighlightFromQueryOrManually>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default SingleHighlightedFieldData;
