import React, { useState } from "react";
import replaceSpecialChars from "../../common/replace-special-chars";
import SingleResultPatent from "./single-result-patent";
import { IoIosFunnel } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import RWireButton from "../rwire-ui/rwire-button/rwire-button";
import RwireAppliedFilters from "../common/filters/rwire-applied-filters";
import SingleHighlightedFieldData from "./single-highlighted-field-data";
import NoResultsFound from "../../common/no-results-found";
import { HighlightFromQueryOrManually } from "../../container/highlight";
import { headingMapping } from "../../common/quick-view-heading-map";
import { filterHighlightData } from "../../action/quick-view";
import QuickViewImages from "./quick-view-images";
import { Modal } from "rsuite";
import { IoMdClose } from "react-icons/io";
import { openPatentInNewTab } from "../../common/open-new-tab";

const QuickViewBody = (props) => {
  const {
    displayData = [],
    hideQueryToggle = true,
    setIsImageCarouselModalOpen,
    isImageCarouselModalOpen,
    setImagePath,
    setIsFilterModalOpen,
    onSetFilter,
    isLoadingResult,
    isLoadingTable,
    scrollTableTop,
    quickViewHighlights,
    OnQuickViewOnPN,
    filtersSelected,
  } = props;
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openTabs, setOpenTabs] = useState([]);
  const [isVisibleMessageModal, setVisibilityMessageModal] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState("");
  let { locale } = useParams();

  const handleOpenImagecarousel = (path) => {
    setIsImageCarouselModalOpen(!isImageCarouselModalOpen);
    setImagePath(path);
  };

  const onSetPatentLink = (id) => {
    onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: false });
    sessionStorage.setItem("patentId", id);
    openPatentInNewTab(
      id,
      locale,
      openTabs,
      setOpenTabs,
      setMessageModalContent,
      setVisibilityMessageModal
    );
  };

  return (
    <div className="d-flex quick-view-body pt-2 h-100">
      {!isLoadingResult && !isLoadingTable && displayData.length === 0 ? (
        <NoResultsFound />
      ) : (
        <>
          <div className="col-md-5 ps-0 pe-2">
            <div className="view-boxes blue-bg h-100">
              <div className="px-2 py-1 match-header vertical-center justify-content-center position-relative">
                <div className="text px-2 py-1">Results</div>
                <div
                  className="cursor-pointer px-1 mx-2 filter-btn py-1 d-flex gap-1"
                  onClick={() => setIsFilterModalOpen(true)}
                >
                  <div className="vertical-center">Filters</div>
                  <div className="vertical-center">
                    <IoIosFunnel />
                  </div>
                </div>
              </div>
              <div
                ref={scrollTableTop}
                className={`
                  ${
                    Object.keys(filtersSelected).length === 0 && hideQueryToggle
                      ? "partial-results-list"
                      : ""
                  }
                  ${
                    Object.keys(filtersSelected).length === 0 &&
                    !hideQueryToggle
                      ? "full-results-list"
                      : ""
                  }
                  ${
                    Object.keys(filtersSelected).length !== 0 && hideQueryToggle
                      ? "partial-results-filter-list"
                      : ""
                  }
                  ${
                    Object.keys(filtersSelected).length !== 0 &&
                    !hideQueryToggle
                      ? "full-results-filter-list"
                      : ""
                  }
                borderSect px-2`}
              >
                {displayData &&
                  displayData.map((result, index) => (
                    <SingleResultPatent
                      result={displayData[index]}
                      onSetPatentLink={onSetPatentLink}
                      setSelectedIndex={setSelectedIndex}
                      index={index}
                      selectedIndex={selectedIndex}
                      highlightResult={result}
                      OnQuickViewOnPN={OnQuickViewOnPN}
                      quickViewHighlights={quickViewHighlights}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-md-7 ps-2 pe-0 d-flex flex-column">
            <RwireAppliedFilters {...props} />
            <div className="view-boxes quick-view-body gray-bg px-2 d-flex flex-column">
              <div className="match-header d-flex justify-content-between align-items-end">
                <div className="py-1 d-flex gap-2">
                  <div
                    className="text px-2 py-1 cursor-pointer pubno-header"
                    onClick={() =>
                      onSetPatentLink(
                        displayData?.[selectedIndex]?._source?.PN_B
                      )
                    }
                  >
                    {displayData?.[selectedIndex]?._source?.PN_B}
                  </div>
                  <div
                    className={`text px-2 py-1 ${
                      displayData?.[
                        selectedIndex
                      ]?._source?.ALD.toLowerCase() === "dead"
                        ? "pub-status-red"
                        : "pub-status-green"
                    }`}
                  >
                    {displayData?.[selectedIndex]?._source?.ALD}
                  </div>
                </div>
                <div className="quick-btn">
                  <RWireButton
                    buttonCName="match-btn header-table-result-page"
                    onClick={() => {
                      navigate("/en/rwire-patents");
                    }}
                    name="Exit"
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <div
                  className={`${
                    Object.keys(filtersSelected).length === 0 && hideQueryToggle
                      ? "partial-results-list"
                      : ""
                  }
                  ${
                    Object.keys(filtersSelected).length === 0 &&
                    !hideQueryToggle
                      ? "full-results-list"
                      : ""
                  }
                  ${
                    Object.keys(filtersSelected).length !== 0 && hideQueryToggle
                      ? "partial-results-filter-list"
                      : ""
                  }
                  ${
                    Object.keys(filtersSelected).length !== 0 &&
                    !hideQueryToggle
                      ? "full-results-filter-list"
                      : ""
                  } borderSect details-sect h-100`}
                >
                  {displayData.length > 0 && (
                    <>
                      <QuickViewImages
                        displayData={displayData}
                        selectedIndex={selectedIndex}
                        handleOpenImagecarousel={handleOpenImagecarousel}
                        {...props}
                      />
                      <div className="fw-bold text-black title-text px-2 py-1">
                        <HighlightFromQueryOrManually>
                          {replaceSpecialChars(
                            displayData?.[selectedIndex]?._source?.TI_EN
                          )}
                        </HighlightFromQueryOrManually>
                      </div>

                      {Object.keys(headingMapping).map((field, index) => (
                        <SingleHighlightedFieldData
                          displayData={filterHighlightData(
                            quickViewHighlights?.[selectedIndex]?.highlight
                              ? quickViewHighlights?.[selectedIndex]?.highlight
                              : []
                          )}
                          selectedIndex={selectedIndex}
                          field={field}
                          key={index}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Modal
            className="modalClassMultitab"
            static="static"
            keyboard={true}
            open={isVisibleMessageModal}
          >
            <div
              className="highlight-modal-close-btn"
              onClick={() => setVisibilityMessageModal(false)}
            >
              <IoMdClose className="modal__close" title="Close" />
            </div>
            <div>{messageModalContent}</div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default QuickViewBody;
