import React from "react";
import RwireJumpPages from "../common/patents-result-common/rwire-jump-pages";

const QuickViewJumpPage = (props) => {
  const {
    onSetResultTable,
    onPerformSearch,
    dataSize,
    totalRecordsCount,
    navigatePath,
    selectedIncludes,
  } = props;

  return (
    <>
      <RwireJumpPages
        onSetResultTable={onSetResultTable}
        onPerformSearch={onPerformSearch}
        dataSize={dataSize}
        totalRecordsCount={totalRecordsCount}
        navigatePath={navigatePath}
        selectedIncludes={selectedIncludes}
      />
    </>
  );
};

export default QuickViewJumpPage;
