import React, { useEffect, useState } from "react";
import "./styles.scss";
import moment from "moment";
import EditIcon from "@rsuite/icons/Edit";
import VisibleIcon from "@rsuite/icons/Visible";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";
import { useNavigate } from "react-router-dom";
import { RwireProjectModal } from "../rwire-project-creation-wrapper";
import { ROLE_OWNER } from "../../../common/user-roles";
import { ACCESS_COMMENTOR } from "./../../../common/user-roles";
import SharedWithCell from "./shared-with-cell";

// Show tooltip if the textarea is empty
const Tooltip = ({ message, children }) => (
  <div className="project-name-error-tooltip-text">{message}</div>
);

const ProjectTable = ({
  data,
  onUpdateReportProject,
  onResetTablesData,
  onSetApp,
  onSetProjectReport,
  onGetReportProjectDataById,
  onGetAllProjectUsers,
  onGetBackwardCitationsData,
}) => {
  const [projects, setProjects] = useState(data);
  const [editableRow, setEditableRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setProjects(data);
  }, [data]);

  const editProject = (id) => {
    setErrors({});
    setEditableRow(id);
  };
  // manages textarea changes for a project name and it's validations
  const handleTextareaChange = (e, projectId) => {
    const newName = e.target.value.trim();
    const hasInput = newName !== "";
    setIsSaveDisabled(!hasInput);

    let newErrors = { ...errors };
    if (newName === "") {
      newErrors.name = "Please fill in this field.";
    } else {
      newErrors.name = "";
    }

    const isDuplicate = projects.some(
      (project) => project.name === newName && project.id !== projectId
    );

    if (isDuplicate) {
      newErrors.name = "Project name already exists.";
      setIsSaveDisabled(true);
    }

    const updatedProjects = projects.map((p) =>
      p.id === projectId
        ? {
            ...p,
            name: newName,
            borderColor: newErrors.name ? "#dc3545" : "#3498ff",
          }
        : p
    );

    setProjects(updatedProjects);
    setErrors(newErrors);
  };

  const saveProject = async (id) => {
    try {
      if (!projects[id].name) {
        throw new Error("Please fill in this field.");
      }

      await onUpdateReportProject({
        id: projects[id].id,
        body: {
          name: projects[id].name,
        },
      });

      setErrors({});
      setTimeout(() => {
        setErrors({});
      }, 3000);

      setEditableRow(null);
    } catch (error) {
      setErrors({ name: error.message });

      setTimeout(() => {
        setErrors({});
      }, 3000);
    }
  };

  const resetEditableRow = (id) => {
    setEditableRow(null);
  };

  const closeEditableRow = (id) => {
    if (editableRow === id) {
      const originalName = data.find((project) => project.id === id)?.name;
      const updatedProjects = projects.map((p) =>
        p.id === id ? { ...p, name: originalName, borderColor: "#3498ff" } : p
      );
      setProjects(updatedProjects);
      resetEditableRow(id);
    }
  };

  const viewProject = async (id, name, access) => {
    sessionStorage.setItem("projectId", id);
    sessionStorage.setItem("projectMode", true);
    sessionStorage.setItem("userProjectAccess", access);
    onSetProjectReport({ projectName: name });

    onResetTablesData();
    setShowModal(true);
    const data = await onGetReportProjectDataById(id);

    await onGetAllProjectUsers(id);
    onSetProjectReport({
      workspaceElements: data.workspaceElements.split(",").map(Number),
    });
    setTimeout(() => {
      onSetApp({
        isProjectMode: true,
      });
      setShowModal(false);
      if (access === ACCESS_COMMENTOR) navigate("/en/report/notes");
      else navigate("/en/report");
      // const firstElement = data.workspaceElements.split(",")[0];
    }, 2000);
    await onGetBackwardCitationsData();
  };

  const formatDateForUI = (timestamp) => {
    return moment.unix(timestamp).format("DD-MM-YYYY");
  };
  //   const formatUnixTimestamp = (timestamp) => {
  //     const date = new Date(timestamp * 1000);
  //     return date.toISOString().split("T")[0];
  //   };

  return (
    <div className="table-container">
      <table className="table-borderless">
        <thead className="table-header">
          <tr>
            <th className="project-name">Project Name</th>
            {/* <th className="project-created-by">Created By</th> */}
            <th className="project-edit"></th>
            {/* <th className="project-type">Project Type</th> */}
            <th className="project-owner">Project Owner</th>
            <th className="project-st-date">Start Date</th>
            <th className="project-shared-with">Shared With</th>
            <th className="project-actions text-center">View</th>
          </tr>
        </thead>
        <tbody>
          {projects &&
            projects.length > 0 &&
            projects.map((project, index) => (
              <tr
                key={project.id}
                className={`${editableRow === project.id ? "selected" : ""}`}
              >
                <td className="project-name position-relative">
                  {editableRow === project.id ? (
                    <>
                      {errors.name && <Tooltip message={errors.name}></Tooltip>}
                      <div className="d-flex">
                        <textarea
                          rows={1}
                          className="rs-input"
                          value={project.name}
                          onChange={(e) => handleTextareaChange(e, project.id)}
                          placeholder="Type a project name"
                          style={{ border: `1px solid ${project.borderColor}` }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="d-flex align-items-center">
                      {project.name}
                    </div>
                  )}
                </td>
                <td className="project-edit">
                  {editableRow === project.id ? (
                    <div>
                      <button
                        onClick={() => saveProject(index)}
                        disabled={isSaveDisabled}
                      >
                        <CheckIcon className="action-icon" />
                      </button>
                      <button onClick={() => closeEditableRow(project.id)}>
                        <CloseIcon className="action-icon" />
                      </button>
                    </div>
                  ) : (
                    <div className="justify-content-center">
                      {project?.role === ROLE_OWNER && (
                        <button onClick={() => editProject(project.id)}>
                          <EditIcon className="edit-icon" />
                        </button>
                      )}
                    </div>
                  )}
                </td>
                <td className="project-owner">{project.createdBy}</td>
                <td className="project-st-date">
                  {formatDateForUI(project.createdAt)}
                </td>
                <td className="project-shared-with">
                  <SharedWithCell
                    project={project}
                    userProjectAccess={project.access}
                  />
                </td>
                <td className="project-actions">
                  <div className="d-flex justify-content-center">
                    <button
                      onClick={() =>
                        viewProject(project.id, project.name, project.access)
                      }
                      title="View"
                    >
                      <VisibleIcon className="action-icon" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {showModal && <RwireProjectModal open={showModal} />}
    </div>
  );
};

export default ProjectTable;
