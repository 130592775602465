const notConsiderFields = ["CLCN", "INCN"];

const subtractArrays = (array1, array2) => {
  return array1.filter((element) => !array2.includes(element));
};

export const getMostRelevantHighlights = (highlightedData = []) => {
  if (highlightedData && highlightedData.length === 0) return "";
  Object.keys(highlightedData).forEach((key) => {
    if (
      Array.isArray(highlightedData[key]) &&
      highlightedData[key].length === 0
    ) {
      delete highlightedData[key];
    }
  });

  const motRelevantField = subtractArrays(
    Object.keys(highlightedData),
    notConsiderFields
  );
  // Retrieve the most relevant field's highlights
  const relevantHighlights = highlightedData[motRelevantField[0]];
  if (relevantHighlights) {
    let mostRelevantHighlight = relevantHighlights[0];

    return mostRelevantHighlight;
  }
};
